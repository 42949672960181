module.exports = {
  defaultTitle: "Portfolio | Hassaan Zuberi",
  logo: "./src/assets/images/website-logo.svg",
  author: "Hassaan Zuberi",
  url: "https://hassaanzuberi.com/",
  legalName: "Hassaan Zuberi Portfolio",
  defaultDescription:
    "Hassaan is a Web engineer and he loves to develop performant and efficient applications. Take a peek at what he is upto these days.",
  socialLinks: {
    github: "https://github.com/HAZberi",
    linkedin: "https://www.linkedin.com/in/hassaanzuberi/",
  },
  googleAnalyticsID: "UA-88875900-4",
  themeColor: "#6b63ff",
  backgroundColor: "#fafafa",
  social: {
    facebook: "appId",
    linkedin: "hassaanzuberi",
    github: "HAZberi",
  },
  address: {
    city: "Calgary",
    region: "Alberta",
    country: "Canada",
    postalCode: "T3RIJ4",
  },
  contact: {
    email: "mhazuberi@gmail.com",
    phone: "(587)-429-1176",
  },
  foundingDate: "2021",
}
