module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio | Hassaan Zuberi","short_name":"Hassaan Zuberi Portfolio","start_url":"https://hassaanzuberi.com/","background_color":"#fafafa","theme_color":"#6b63ff","display":"minimal-ui","icon":"./src/assets/images/website-logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1bf03b9d7d0adf7f5a0260e12719d66b"},
    }]
